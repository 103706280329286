import styles from "./input.module.scss";

export default function CamelotInput(props: {
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type?: string;
  name?: string;
  autocomplete?: string;
  value?: string
}) {
  return (
    <div className={styles.main}>
      <label>{props.label}</label>
      <div className={styles.inputs}>
        <input value={props.value} type={props.type} name={props.name} onChange={props.onChange} autoComplete={props.autocomplete}></input>
      </div>
    </div>
  );
}
