import { useState } from "react";
import CamelotButton from "../../components/CamelotButton";
import CamelotInput from "../../components/CamelotInput";
import styles from "./toVote.module.scss";
import { ballotLinkGET } from "../../services/ballot_link_get";
import logo from "../../assets/c_logo.svg";
import errorIcon from "../../assets/error_icon.svg";
import successIcon from "../../assets/success_icon.svg";


export function ToVote() {
  const [codiceFiscale, setCodiceFiscale] = useState<string>();
  const [link, setLink] = useState<string>();
  const [notAllow, setNotAllow] = useState<boolean>(false);

  async function getLink(codiceFiscale: string) {
    try {
      const resp = await ballotLinkGET(codiceFiscale);
      if (resp?.statusCode === 404) {
        setLink("Utente non trovato");
        setNotAllow(true);
      } else {
        setLink(`${resp?.responseBody!}&redirect=true`);
        window.location.href = `${resp?.responseBody!}&redirect=true`;
      }
      setTimeout(() => {
        setNotAllow(false);
        setLink(undefined);
      }, 3000);
    } catch (error) {}
  }

  return (
    <div className={styles.main}>
      <div className={styles.sidebar}>
        <img src={logo} alt={"logo"} />
      </div>
      <div className={styles.body}>
        <div className={styles._form}>
          <p style={{ fontSize: "20px" }}>Inserisci i tuoi dati identificativi</p>
          <CamelotInput
            value={codiceFiscale}
            label="Codice fiscale"
            onChange={(e) => {
              setCodiceFiscale(e.target.value.toUpperCase());
            }}
          />
          <CamelotButton
            text="Invia"
            disabled={codiceFiscale == null || codiceFiscale == ""}
            action={async () => {
              await getLink(codiceFiscale!);
              setCodiceFiscale("");
            }}
          />
        </div>
        {notAllow && link === "Utente non trovato" ? (
          <div className={styles._response}>
            <div className={styles.__notFound}>
              <img src={errorIcon} alt="error" />
              <strong>Errore</strong>
              <p>Utente non abilitato al voto</p>
            </div>
          </div>
        ) : 
         link != null ? (
          <div className={styles._response}>
            <div className={styles.__found}>
              <img src={successIcon} alt="error" />
              <strong>Stai per essere indirizzato alla votazione!</strong>
            </div>
          </div>
        )
        : null}
      </div>
    </div>
  );
}
