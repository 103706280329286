import { useEffect, useState } from "react";
import styles from "./App.module.scss";
import CamelotButton from "./components/CamelotButton";
import CamelotInput from "./components/CamelotInput";
import { doSearchCF } from "./services/search_CF";
import { ballotLinkPUT } from "./services/ballot_link_put";
import logo from "./assets/c_logo.svg";
import backofficeLogo from "./assets/backoffice_logo.svg";
import errorIcon from "./assets/error_icon.svg";
import successIcon from "./assets/success_icon.svg";
import { Identificativo } from "./models/identificativo";

function App() {
  const [identificativo, setIdentificativo] = useState<Identificativo>({codiceFiscale: "", frazione: ""});
  const [found, setFound] = useState<Response | null>();

  enum Response {
    found,
    success,
    alreadyEnabled,
    notEnabled,
  }

  async function searchCF(codiceFiscale: string) {
    try {
      const resp = await doSearchCF(codiceFiscale);
      if (resp?.statusCode === 404) {
        setFound(Response.notEnabled);
      } else {
        setFound(Response.found);
        setIdentificativo({...identificativo, frazione: resp?.responseBody!})
      }
    } catch (error) {
      setFound(Response.notEnabled);
    }
  }

  async function putBallotLink(codiceFiscale: string, frazione: string) {
    try {
      const link = await ballotLinkPUT(codiceFiscale, frazione);
      if (link?.statusCode === 200) {
        setFound(Response.success);
        console.log("abilitato correttamente");
      } else if (link?.statusCode === 500) {
        setFound(Response.alreadyEnabled);
        console.log("già abilitato");
      } else {
        setFound(Response.notEnabled);
        console.log("non abilitato");
      }

      setTimeout(() => {
        setFound(null);
      }, 3000);
    } catch (error) {}
  }

  useEffect(() => {
    console.log(found);
  }, [found]);

  return (
    <div className={styles.main}>
      <div className={styles.sidebar}>
        <img src={logo} alt={"logo"} />
      </div>
      <div className={styles.body}>
        <img src={backofficeLogo} alt={"logo"} />

        <div className={styles._form}>
          <p style={{ fontSize: "20px" }}>
            Inserisci le informazioni utente per abilitare il voto
          </p>
          <CamelotInput
            value={identificativo?.codiceFiscale}
            label="Codice fiscale"
            onChange={(e) => {
              setIdentificativo({
                ...identificativo,
                codiceFiscale: e.target.value.toUpperCase(),
              });
            }}
          />
          {/* <CamelotInput
            value={identificativo?.codiceFiscale}
            label="Circoscrizione"
            onChange={(e) => {
              setIdentificativo({
                ...identificativo,
                frazione: e.target.value.toUpperCase(),
              });
            }}
          /> */}
          <CamelotButton
            ghost
            text="Effettua ricerca"
            disabled={
              identificativo.codiceFiscale === ""
            }
            action={() => searchCF(identificativo?.codiceFiscale!)}
          />
        </div>

        {found === Response.found ? (
          <div className={styles._response}>
            <div className={styles.__abilita}>
              <div className={styles.___result}>
                <p>Risultato</p>
                <p>{identificativo?.codiceFiscale}</p>
                <p>{identificativo?.frazione}</p>
              </div>
              <CamelotButton
                text="Abilita al voto"
                action={async () => {
                  await putBallotLink(
                    identificativo?.codiceFiscale!,
                    identificativo?.frazione!
                  );
                  setIdentificativo({ codiceFiscale: "", frazione: "" });
                }}
              />
            </div>
          </div>
        ) : found === Response.notEnabled ? (
          <div className={styles._response}>
            <div className={styles.__notFound}>
              <img src={errorIcon} alt="error" />
              <strong>Errore</strong>
              <p>Utente non abilitato al voto</p>
            </div>
          </div>
        ) : found === Response.alreadyEnabled ? (
          <div className={styles._response}>
            <div className={styles.__notFound}>
              <img src={errorIcon} alt="error" />
              <strong>Errore</strong>
              <p>Utente già abilitato al voto</p>
            </div>
          </div>
        ) : found === Response.success ? (
          <div className={styles._response}>
            <div className={styles.__found}>
              <img src={successIcon} alt="success" />
              <strong>Utente abilitato correttamente</strong>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default App;
