import axios, { AxiosError } from "axios";
import { ApiResponse } from "../models/api_response";
import { endpoint } from "./base_url";

export async function ballotLinkPUT(codFiscale: string, frazione: string): Promise<ApiResponse | undefined> {
  try {
    const response = await axios.put(`${endpoint}/ballotLink`, {cod_fiscale: codFiscale, frazione: frazione});
    return {
      statusCode: response.status,
      responseBody: response.data.content,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        statusCode: error.response!.status,
        responseBody: error.response?.data.content,
      };
    }
    console.log(error);
    return undefined;
  }
}