import styles from "./button.module.scss";
import searchIcon from "../../assets/search_icon.svg";

export default function CamelotButton(props:{text:string, action?: (e: any) => unknown; ghost?: boolean,disabled?: boolean, width?: string}){
    return(
        <div className={props.ghost ? styles.wrapper : ""}>
            {props.ghost && <img src={searchIcon} alt="search"/>}
            <button style={{width: props.width}} className={props.ghost? styles.ghostButton : styles.mainButton} disabled={props.disabled} onClick={async (e) => props.action && props.action(e)}>{props.text}</button>
        </div>
    )
}