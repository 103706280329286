import axios, { AxiosError } from "axios";
import { ApiResponse } from "../models/api_response";
import { endpoint } from "./base_url";

export async function ballotLinkGET(
  codiceFiscale: string,
): Promise<ApiResponse | undefined> {
  try {
    const response = await axios.get(
      `${endpoint}/ballotLink?cod_fiscale=${codiceFiscale}`);
    return {
      statusCode: response.status,
      responseBody: response.data.content,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        statusCode: error.response!.status,
        responseBody: error.response?.data.content,
      };
    }
    console.log(error);
    return undefined;
  }
}
